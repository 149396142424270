import * as React from "react"

import banner from "../images/witness-coffee.jpg"
import instagram from "../images/ig.png"

// styles
const pageStyles = {
  color: "#232129",
  backgroundColor: "#0c0c0c",
  padding: "16px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const bannerStyle = {
  float: 'left',
  margin: '8px',
  width: '100%',
}

const placardStyle = {
  color: 'white',
  padding: '12px'
}

const peaceStyle = {
  color: 'white',
  fontSize: 'large',
  textAlign: 'center',
}

const igStyle = {
  textAlign: 'center',
  width: '32px',
  height: '32px',
  margin: '8px',
}

const footer = {
  fontSize: 'x-small',
}

const signatureStyle = {
  fontFamily: 'Brush Script MT, cursive',
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Witness Coffee</title>
      <img
        style={bannerStyle}
        alt="Witness Coffee"
        src={banner}
      />
      <div style={placardStyle}>
      Coffee is a ritual &mdash; a moment of stillness with which we begin each day. We believe there's a lot to learn about ourselves &amp; others simply by witnessing ourselves, or another, exactly as they are. With that, we hope enjoy this coffee with love, compassion, &amp; non-judgement for yourself &amp; another.
      </div>
      <div style={peaceStyle}>
      Peace, love, &amp; coffee.<br />
      <div style={signatureStyle}>
      Witness Coffee Roasters
      </div>
      <a href="https://www.instagram.com/witnesscoffeeroasters/"><img alt="instagram" style={igStyle} src={instagram} /></a>
      </div>
      <div style={footer}>&copy;2021 Witness Coffee, Ltd. Topanga Canyon. California.</div>
    </main>
  )
}

export default IndexPage
